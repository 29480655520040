body {	
	font-family: 'Ubuntu', sans-serif;
	background: url(../img/bg.png);
}
.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
    padding-right: 0; 
    padding-left: 0; 
}
.container {
	background: #fff;
	-webkit-box-shadow: 0px 9px 30px -2px rgba(0,0,0,0.5);
-moz-box-shadow: 0px 9px 30px -2px  rgba(0,0,0,0.5);
box-shadow: 0px 9px 30px -2px rgba(0,0,0,0.5);
}
.logo__img {
	background: url(../img/logo.png);
	width: 88px;
	height: 97px;
	margin: 30px 26px 40px 30px;

}
.logo__title {
	font-weight: 500;
	font-size: 22px;
	margin-top: 40px;
}
.descriptor {
	font-weight: 400;
	font-size: 17px;
}
.left {
	float: left;
}
.logo {
	width: 360px;
	margin: 0 auto;
   }
.logo__text {
	width: 170px;
}
.razdelitel {
	margin-top: 28px;
	height: 99px;
	width: 2px;
	background: #bfbfbf;
	margin-left: 30px;
}
.hed1{
	font-family: 'Ubuntu', sans-serif;
	font-weight: 500;
	font-size: 15px;
	padding: 0;
	width: 180px;
	float: left;
	margin-top: 7px;
	padding-right: 10px;
}
.hed2{
	font-weight: 400;
	font-size: 15px;
	padding: 0;
	float: left;
	line-height: 1.3;
    padding-bottom: 10px;
}
.vrach{
	padding-top: 35px;
    padding-left: 18px;
}

.call__tel {
	background: url(../img/call.png);
	width: 53px;
	height: 53px;
    margin: 52px 32px 62px 38px;
    display: inline-block;
    float: left;
}
.zayavka {
	font-family: 'Ubuntu', sans-serif;
	font-weight: 500;
	font-size: 21px;
	padding-bottom: 4px;
}
.telepnone a {
	color: #dd3263;
	font-family: 'Ubuntu', sans-serif;
	font-weight: 500;
	font-size: 21px;

}
.tell__n {
	padding-top: 43px;
}
.nav {
	background: #0783bf;
}
.nav ul {
	padding: 0 !important;
    margin: 0 !important;
}
.nav li {
	list-style-type: none;
	display: inline-flex;
}
.nav li a {
	color: #fff;
	font-size: 17px;
	padding: 17px 17px;
}
.nav li a .fa {
	margin-right: 5px;
}
.nav li a:hover {
	background: #dd3263;
	text-decoration: none;
}
.slider_text {
	background: #dd3263;
	width: 57%;
	height: 28.5%;
	position: absolute;
	bottom: 0;
	left: 0; 
	color: #fff;  
}
.slider_text span {
 font-size: 38px;
 font-family: 'Ubuntu', sans-serif;
 font-weight: 300;
 font-style: italic;
 display: block;
 padding: 5%;
}
/* PAGER */
.bx-wrapper .bx-pager {
  text-align: center;
  font-size: .85em;
  color: #fff;
  padding-top: 20px;
  position: absolute;
      bottom: 35px;
    right: 160px;

}
.bx-wrapper .bx-pager.bx-default-pager a {
  text-indent: -9999px;
  display: block;
  width: 25px;
  height: 25px;
  margin: 0 10px;
  outline: 0;
background: url(../img/page.png);
}
.bx-wrapper .bx-pager.bx-default-pager a:hover,
.bx-wrapper .bx-pager.bx-default-pager a.active,
.bx-wrapper .bx-pager.bx-default-pager a:focus {
background: url(../img/page-a.png);
}
.bx-wrapper .bx-pager-item,
.bx-wrapper .bx-controls-auto .bx-controls-auto-item {
  display: inline-block;
  vertical-align: bottom;
  *zoom: 1;
  *display: inline;
}
.bx-wrapper .bx-pager-item {
  font-size: 0;
  line-height: 0;
}

/* DIRECTION CONTROLS (NEXT / PREV) */
.bx-wrapper .bx-prev {
  left: 10px;
  background: url('../img/next.png') no-repeat 0 0;
}
/* .bx-wrapper .bx-prev:hover,
.bx-wrapper .bx-prev:focus {
  background-position: 0 0;
} */
.bx-wrapper .bx-next {
  right: 10px;
  background: url('../img/prev.png') no-repeat 0 0;
}
/* .bx-wrapper .bx-next:hover,
.bx-wrapper .bx-next:focus {
  background-position: -43px 0;
} */
.bx-wrapper .bx-controls-direction a {
  position: absolute;
  top: 45%;
  margin-top: -16px;
  outline: 0;
  width: 64px;
  height: 64px;
  text-indent: -9999px;
  z-index: 9999;
}
.bx-wrapper .bx-controls-direction a.disabled {
  display: none;
}
h1 {
	font-size: 35px;
	text-align: center;
}
.d5 {
	width: 0; height: 0;
	border-top: 10px solid transparent;
    border-right: 270px solid #45a2cf;
    border-bottom: 10px solid transparent;
	float: right;
    margin: 4% 0;
}

.d6 {
width: 0; height: 0;
border-top: 10px solid transparent;
border-left: 270px solid #45a2cf;
border-bottom: 10px solid transparent;
    margin: 4% 0;
}
.title__page {
	padding: 70px 0;
}
.text__page {
	text-align: justify;
	padding: 0 10% ;
	font-size: 16px;
	font-family: 'Open-Sans', sans-serif;
	font-weight: 500;
	letter-spacing: 1px;
	padding-bottom: 90px;

}
.text__page p {
	 text-indent: 20px;
}
.footer	{
	background: #0783bf;
	color: #fff;
	padding: 20px 0 ;
	text-align:center;
	font-family: 'Ubuntu', sans-serif;
	}
footer {
	    padding-bottom: 65px;
}
.zap__text {
 font-size: 21px;
 font-weight: 500;
}
.zap__tel  a{
	font-weight: bold;
	 font-size: 25px;
	 color: #fff;
}
.Copyright {
	padding: 25px;
}
.autor {
	padding: 15px;
}

.mmenu {
	    background-color: #0783bf;
    color: #fff;
    margin: 0;
    padding: 13px;
    width: 100%;
    font-size: 20px;
}
.nav select {
  display: none;
}
.nav select {
    background-color: #0783bf;
    color: #fff;
    margin: 0;
    padding: 13px;
    width: 100%;
    font-size: 20px;
	background: url(../img/arr.png);
	background-size: 40px;
	 background-color: #0783bf;
	 background-repeat: no-repeat;
     background-position: right 13px center;
    padding-right: 30px;
   -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none !important;
}


@media screen and (max-width: 1200px) {
		.razdelitel {
			margin-left: 7px;
		}
		.vrach {
			padding-top: 33px;
			margin: 0 auto;
		    width: 360px;
		}
		.call__tel {
			    margin: 52px 20px 62px 31px;
		}
		.nav li a {
		    padding: 17px 18px;
		}
			.razdelitel {
			display: none;
	}
}
@media screen and (max-width: 992px) {
		.razdelitel {
		    margin-left: 58px;
		}
		.tel{
			 margin: 0 auto;
    		width: 360px;
		}
		.call__tel {
   			 margin: 8px 20px 29px 31px;
		}
		.tell__n {
    		padding-top: 0px;
		}
		.nav li a {
   			 padding: 16px 45px;
		}
		.bx-wrapper .bx-pager {
			right: 70px;
		}
		 .hidden-xs,.d6,.d5{
   			display:none;
   		}
   		.slider_text {
   			width: 50%;
   		}
   		.slider_text span {
   			font-size: 28px;
   		}
   		.razdelitel {
		 	height: 2px;
		    width: 100%;
		    margin-left: 0 !important;
		    margin-bottom: 10px;
		    margin-top: 0;
		    background: #bfbfbf;
		    display: block;
		}
		.vrach {
			padding-top: 10px;		
		}
		.nav ul     { display: none; }
 		 .nav select { display: inline-block; }

}

@media screen and (max-width: 544px) {
   .logo {
   	text-align: center;
    margin: 0 auto;
    width: 360px; 
   }
   .vrach {
    text-align: center;
    margin: 0 auto;
    width: 360px;
   }
   .tel {
    text-align: center;
    margin: 0 auto;
    width: 340px;
   }
   .d6,.d5{
   	display:none;
   }
   .nav li {
   width: 100%;
	}
	.slider_text {
    width: 100%;
    height: 28.5%;
    bottom: 70px;
	}
	.slider_text span {
    font-size: 29px;
	}
	.razdelitel {
		    height: 2px;
		    width: 100%;
		    margin-left: 0 !important;
		    margin-bottom: 10px;
		    margin-top: 0;
		    background: #bfbfbf;
		    display: block;
	}
	.vrach {
		padding-top: 0;
	}
	
}
	
